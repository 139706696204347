import axios from 'axios';
import store from "@/store";
// import { ElMessage } from 'element-plus';
// import router from "../router";

const ContentType = {
  JSON: 'application/json;charset=UTF-8',
  FORM: 'application/x-www-form-urlencoded;charset=UTF-8',
  UPLOAD: 'multipart/form-data',
  STREAM: 'application/octet-stream;charset=UTF-8'
}

axios.defaults.timeout = 5000;
axios.defaults.baseURL = store.state.baseURL // 'http://admin.yijiangkeji.com.cn/';


//http request 请求拦截器
axios.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type': ContentType.JSON
    }
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


//http res 响应拦截器
axios.interceptors.response.use(
  res => {
    // if (res.data.code === 200) {
      return res;
    // } else {
    //   // let {code, msg} = res.data
    //   // codeHandle(code, msg)
    //   return Promise.reject(res)
    // }
  },
  error => {
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err)
      })
  })
}

/**
 * @description: code处理
 * @param {number} code
 * @param {string} message
 */
// const codeHandle = (code, message) => {
//   switch (code) {
//     case 401:
//       ElMessage.error(message)
//       router.push({
//         path: "/login",
//         query: { redirect: router.currentRoute.fullPath } // 从哪个页面跳转
//       })
//       break
//     default:
//       ElMessage.error(message)
//       break
//   }
// }
