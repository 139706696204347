import { createStore } from 'vuex'
// const requireModules = require.context('./modules', true, /\.(ts|js)$/iu)
// const modules = {}
// requireModules.keys().forEach(filePath => {
//   const modular = requireModules(filePath)
//   modules[filePath.slice(2, -3)] = {
//     namespaced: true,
//     ...modular.default
//   }
// })

export default createStore({
  state: {
    baseURL: 'http://admin.yijiangkeji.com.cn/',
    CPFlag: "0", // 产品是否可以进入详情 1开启 0关闭
    ALFlag: "0" // 案例是否可以进入详情 1开启 0关闭
  },
  getters: {
  },
  mutations: {

  },
  actions: {
    setCPFlag({state}, params){
      state.CPFlag = params
    },
    setALFlag({state}, params){
      state.ALFlag = params
    }
  },
  modules: {
    // ...modules
  }
})
