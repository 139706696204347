// http://admin.yijiangkeji.com.cn
import {get} from '@/utils/request'

// 获取图片轮播
export const getPictureList = q => get('/app/picture/list',q)
export const getVideoList = q => get('/app/video/list',q)
export const getProductsList = q => get('/app/products/list',q)
export const getCasesList = q => get('/app/cases/list',q)
export const getProductCenterDetail = q => get(`/app/products/detail/${q}`)
export const getProjectCaseDetail = q => get(`/app/cases/detail/${q}`)
export const getContactsLoad = q => get(`/app/contacts/load`, q)
export const getAboutLoad = q => get(`/app/about/load`, q)
export const getLxfsList = q => get(`/app/lxfs/list`, q)
export const getCpflList = q => get(`/app/products/cpfl`, q)
export const getLoad = q => get(`/app/detail/flag/load`, q)
export const setFront = q => get(`/app/front`, q)
