import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

import locale from 'element-plus/lib/locale/lang/zh-cn'
import '@/assets/styles/reset.css' //

// import Element from 'element-ui'
// import './permission'
createApp(App).use(ElementPlus, { locale }).use(router).use(store).mount('#app')
