import { createRouter, createWebHashHistory } from 'vue-router'

const routes=[
  //这里和vue2一样
  {
    path: '/',
    redirect: '/home',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/',
    redirect: '/home',
    component: () => import('../views/index.vue'),
    children: [
      {
        path: 'home',
        name: '首页',
        component: () => import('../views/home/index.vue'),
      },
      {
        path: 'productCenter',
        name: '产品中心',
        component: () => import('../views/productCenter/index.vue'),
      },
      {
        path: 'aboutUs',
        name: '关于我们',
        component: () => import('../views/aboutUs/index.vue'),
      },
      {
        path: 'projectCase',
        name: '项目案例',
        component: () => import('../views/projectCase/index.vue'),
      },
      {
        path: 'contactUs',
        name: '联系我们',
        component: () => import('../views/contactUs/index.vue'),
      },
      {
        path: 'productCenterDetail',
        name: '产品中心详情',
        component: () => import('../views/productCenterDetail/index.vue'),
      },
      {
        path: 'projectCaseDetail',
        name: '项目案例详情',
        component: () => import('../views/projectCaseDetail/index.vue'),
      },
    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
