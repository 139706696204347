<template>
  <router-view />
</template>

<script>
import {getLoad, setFront} from "@/api/home";

export default {
  name: 'App',
  created() {
    this.init()
  },
  methods: {
    init() {
      setFront()
      getLoad().then(res => {
        res.data.forEach(e => {
          if (e.pkid == 1) {
            this.$store.dispatch('setCPFlag', e.flag)
          } else if (e.pkid == 2){
            this.$store.dispatch('setALFlag', e.flag)
          }
        })
      })
    }
  }
}
</script>

<style>

</style>
